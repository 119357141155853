import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { BASE_URL, get } from "../../services/Calls";
import SliderArrowLeft from "../../assets/sliderArrowLeft.png";
import SliderArrowRight from "../../assets/sliderArrowRight.png";

const PowerOfTeamSliderNew = () => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);
  const [mouseMovementX, setMouseMovementX] = useState<number>(0);
  const [currentSilderPosition, setCurrentSliderPosition] = useState<number>(0);
  const [mobileView, setMobileView] = useState<boolean>(window.innerWidth <= 768);
  
  const [localHeroes, setLocalHeroes] = useState<any[]>([]);
  const [selectedHero, setSelectedHero] = useState<any>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const countriesRef = useRef<any>();
  const countryRef = useRef<any>();

  useEffect(() => {
    window.addEventListener("mouseup", handleStopDraggingScroll);
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("mouseup", handleStopDraggingScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function updateSize() {
      setMobileView(window.innerWidth <= 768);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (!mobileView && countriesRef.current) {
      countriesRef.current.style.width = countryRef.current?.clientWidth * 0.3125 + "vw";
    }
  }, [mobileView]);

  useEffect(() => {
    getLocalExpertData();
  }, []);

  const getLocalExpertData = async () => {
    try {
      const response = await get(BASE_URL + `/local-experts`);
      if (response?.data?.length > 0) {
        setLocalHeroes(response.data);
      }
    } catch (error) {
      console.error("Error fetching local experts:", error);
    }
  };

  const handleResize = () => {
    setMobileView(window.innerWidth <= 768);
  };

  const handleStartDraggingScroll = () => setIsDragging(true);
  const handleStopDraggingScroll = () => setIsDragging(false);
  const handleMouseEnter = () => setMouseEntered(true);
  const handleMouseLeave = () => setMouseEntered(false);

  const handleMouseMove = (e: any) => {
    setMouseMovementX(e.movementX);
    if (isDragging && mouseEntered) {
      const moveSpeedDirection = e.movementX > 0 ? -e.movementX : Math.abs(e.movementX);
      countriesRef.current.scrollTo(countriesRef.current.scrollLeft + moveSpeedDirection, 0);
    }
  };

  const handleScrollPosition = (e: any) => {
    setCurrentSliderPosition(e.target.scrollLeft);
  };

  const arrowLeft = () => {
    if (currentSilderPosition === countriesRef.current?.scrollWidth - countriesRef.current?.offsetWidth) return;
    countriesRef.current.scrollTo(countriesRef.current.scrollLeft + countryRef.current?.clientWidth, 0);
  };

  const arrowRight = () => {
    if (currentSilderPosition === 0) return;
    countriesRef.current.scrollTo(countriesRef.current.scrollLeft - countryRef.current?.clientWidth, 0);
  };

  return (
    <>
      <MainSliderContainer>
        <Swiper>
          <Arrow
            src={SliderArrowLeft}
            style={{
              opacity: currentSilderPosition === 0 ? 0.5 : 1,
              height: "3.6vw",
              width: "3.6vw",
            }}
            onClick={arrowRight}
          />
          <Countries
            ref={countriesRef}
            onMouseDown={handleStartDraggingScroll}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onScroll={handleScrollPosition}
            style={{
              cursor: "pointer",
              scrollBehavior: isDragging && mouseMovementX ? "initial" : "smooth",
            }}
          >
            {localHeroes.length > 0 &&
              localHeroes.map((localHero: any, i: number) => (
                <Country key={localHero.id || i} ref={countryRef}>
                  <CountryIMGContainer
                    onClick={() => {
                      setSelectedHero(localHero);
                      setIsPopupOpen(true);
                    }}
                  >
                    <CountryIMG src={localHero.imageURL} alt={localHero.name} />
                    {localHero.country?.flagURL && <Flag src={localHero.country.flagURL} />}
                  </CountryIMGContainer>
                </Country>
              ))}
          </Countries>
          <Arrow
            src={SliderArrowRight}
            style={{
              height: "3.6vw",
              width: "3.6vw",
              opacity:
                currentSilderPosition === countriesRef.current?.scrollWidth - countriesRef.current?.offsetWidth
                  ? 0.5
                  : 1,
            }}
            onClick={arrowLeft}
          />
        </Swiper>
      </MainSliderContainer>

      {isPopupOpen && selectedHero && <Popup hero={selectedHero} onClose={() => setIsPopupOpen(false)} />}
    </>
  );
};

// Popup Component
const Popup = ({ hero, onClose }: { hero: any; onClose: () => void }) => {
  if (!hero) return null;

  return (
    <PopupOverlay onClick={onClose}>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>{hero.name}</h2>
        <PopupImage src={hero.imageURL} alt={hero.name} />
        <p style={{marginTop:15,fontSize:20}}><strong>Country:</strong> {hero.country?.name}</p>
        <p style={{maxWidth:800,paddingLeft:20,paddingRight:20,textAlign: 'justify'}}><strong>Description:</strong> {hero.description || "No description available."}</p>
      </PopupContent>
    </PopupOverlay>
  );
};

// Styled Components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90vw;
  width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border:solid 5px #FFC432;
`;

const PopupImage = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 50vh;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
`;

const MainSliderContainer = styled.div`
  padding-top: 4.375vw;
  padding-bottom: 3.75vw;
`;

const Swiper = styled.div`
  width: 74vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const Arrow = styled.img`
  cursor: pointer;
`;

const Countries = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
`;

const Country = styled.div`
  padding: 0 0.91vw;
`;

const CountryIMGContainer = styled.div`
  position: relative;
`;

const CountryIMG = styled.img`
  width: 9.263vw;
  height: 9.263vw;
  border-radius: 50%;
  object-fit: cover;
`;

const Flag = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.8125vw;
  height: 2.8125vw;
  border-radius: 50%;
`;

export default PowerOfTeamSliderNew;
