import React, { useState, useEffect, useRef } from "react";
import "./SelfDrive.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const SelfDrive = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header/>
            <VideoPage pageFrom={'slefDrive'} countryname={""} />
            <div className="destinationPathRow" id="selfDrivePageId">
  <div className="destinationPaginationDiv">
        <span className="destinationPaginationHome" onClick={()=>{navigate('/')}} >Home</span>
        <span className="destinationPaginationBorder"></span>
        <span className="destinationPaginationCountry">Bulgaria</span>
    </div>
    <div className="destinationSocialIconDiv">
        <img className="destinationSocialFbIcon" src={FbBlackIcon} />
        <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
        <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
    </div>
        </div>

            <div className="selfDriveBenefitsDiv">

<div className="selfDriveBenefitsMainHead">
About 4x4 Self-Drive Trips 
</div>
<div className="selfDriveBenefitsItems" style={{textAlign:'justify'}}>
<div >
    {/* <span className="selfDriveBenefitsHead">Flexibility and Independence:</span>&nbsp; */}
    <p className="selfDriveBenefitsDes">Indie4x4 takes you on an unforgettable journey through some of the world’s most breathtaking and remote landscapes. Our self-drive adventures offer a unique way to explore nature, connect with local communities, and enjoy the freedom of the open road—without needing prior off-road driving experience. 
 </p>
    <p className="selfDriveBenefitsDes">
With the support of our expert team and the Indie App, you’ll have everything you need for a seamless, safe, and immersive adventure. </p>
</div>
<div>

<div className="heading">
What to Expect
</div>

    <span className="selfDriveBenefitsHead">Unmatched Nature & Scenery – </span>&nbsp;
    <span className="selfDriveBenefitsDes">Drive through remote landscapes, far from the crowds, and discover hidden gems. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Local Encounters –</span>&nbsp;
    <span className="selfDriveBenefitsDes">Engage with welcoming communities, learn their traditions, and experience authentic cultural exchanges. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Adventure & Activities – </span>&nbsp;
    <span className="selfDriveBenefitsDes">Enjoy soft hiking trails, outdoor adventures, and other exciting experiences along the way. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Comfortable Stays –</span>&nbsp;
    <span className="selfDriveBenefitsDes">Rest in handpicked, high-quality hotels for a perfect balance of adventure and relaxation. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Authentic Culinary Experiences –</span>&nbsp;
    <span className="selfDriveBenefitsDes">Savor regional flavors and traditional dishes that make each destination unique. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Easy Navigation –</span>&nbsp;
    <span className="selfDriveBenefitsDes">The Indie App provides full trip guidance, even offline, ensuring a hassle-free journey. 
    </span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Continuous Support –</span>&nbsp;
    <span className="selfDriveBenefitsDes">Our local experts are available before and during your trip for any assistance you need. </span>
</div>
<div>
    <span className="selfDriveBenefitsDes">Get behind the wheel and embark on a 4x4 adventure like no other—explore, connect, and experience the world on your terms with Indie4x4. </span>
</div>
</div>

            </div>

            <GallerySection />
            <div style={{ height: '6.625vw' }}></div>
            <Footer/>
        </div>
    )
}
export default SelfDrive;
