import "./Header.css";
import React, { useState, useEffect } from "react";
import IndieCircleLogo from "../assets/Indie_circle_logo.png";
import WhiteFacebookLogo from "../assets/whiteFacebookLogo.png";
import MenuIcon from "../assets/menuIcon.png";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

export default function Header() {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      setMobileView(window.innerWidth <= 768);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div className="headerMainContainer">
        <div className="headerOverlayDiv">
          {!mobileView ? (
            // Desktop Header
            <div className="headerInnerContainer">
              <div>
                <div>
                  <img
                    src={IndieCircleLogo}
                    style={{ width: "6.25vw", height: "6.25vw" }}
                    onClick={() => navigate("/")}
                  />
                </div>
                <div className="headerItemMainDiv">
                  <div className="headerItemDiv" onClick={() => navigate("/")}>
                    Home
                  </div>
                  <div className="headerItemDiv" onClick={() => navigate("/Destination")}>
                    Destinations
                  </div>
                  <div className="headerItemDiv" onClick={() => navigate("/HowItWorks")}>How it works</div>
                  <div
                    className="headerItemDiv"
                    onClick={() => navigate("/SelfDrive")}
                  >
                    4X4 Self-Drive
                  </div>

                  {/* About Us with Dropdown */}
                  <div className="headerItemDiv aboutUsContainer">
                    About us
                    <div className="aboutUsDropdown">
                      <div
                        className="dropdownItem"
                        onClick={() => navigate("/OurStory")}
                      >
                        Our Story
                      </div>
                      <div
                        className="dropdownItem"
                        onClick={() => navigate("/OurTeam")  }
                      >
                        Our Team
                      </div>
                      <div
                        className="dropdownItem"
                        onClick={() => navigate("/TermsAndConditions") }
                      >
                        Terms & Conditions
                      </div>
                    </div>
                  </div>

                  <div className="headerItemDiv" onClick={() => navigate("/Sustainability")} >Sustainability</div>
                  <div className="headerItemDiv">Blog</div>
                  <div
                    className="headerItemDiv"
                    onClick={() => navigate("/ContactUs")}
                  >
                    Contact us
                  </div>

                  {/* Fixed Register Button */}
                  <div
                    className="headerItemDiv registerButtonContainer"
                    onClick={() => navigate("/register")}
                  >
                    <div className="registerButton">Register</div>
                  </div>
                </div>
              </div>

              {/* Facebook Logo */}
              <div>
                <img
                  src={WhiteFacebookLogo}
                  style={{ width: "2.1875vw", height: "2.1875vw" }}
                />
              </div>
            </div>
          ) : (
            // Mobile Header with Bootstrap Dropdown
            <div className="headerInnerContainer">
              <div>
                <img
                  src={IndieCircleLogo}
                  style={{ width: "10vw", height: "10vw" }}
                  onClick={() => navigate("/")}
                />
              </div>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img
                    src={MenuIcon}
                    alt=""
                    style={{ width: "7vw", height: "7vw" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ marginTop: "0vw", marginRight: "2vw" }}>
                  <Dropdown.Item onClick={() => navigate("/")}>Home</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/Destination")}>Destinations</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/HowItWorks")}>How it works</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/SelfDrive")}>
                    4X4 Self-Drive
                  </Dropdown.Item>

                  {/* About Us Dropdown in Mobile */}
                  <Dropdown drop="end">
                    <Dropdown.Toggle as="div" className="dropdownCustomToggle">
                      About us
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/OurStory")}>
                        Our Story
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() =>navigate("/OurTeam")}>
                        Our Team
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() =>navigate("/TermsAndConditions") }>
                        Terms & Conditions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown.Item onClick={() => navigate("/Sustainability")}>Sustainability</Dropdown.Item>
                  <Dropdown.Item>Blog</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/ContactUs")}>
                    Contact us
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
