import React, { useState, useEffect, useRef } from "react";
import "./TripPage.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL, post } from "../../services/Calls";
import { ToastContainer, toast } from 'react-toastify';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';


interface IProps {
    tripsData:any;
}

const TripDetailRightSection = ({tripsData}:IProps) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [update, setUpdate] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false)

  async function sendDetails() {
        try {
           // console.log('asdfghjhgfdsa',tripsData.country.name);
            if (name === '') {
                setErrorMsg("Name can't be empty.")
                return;
            }
            if (email === '') {
                setErrorMsg("Email can't be empty.")
                return;
            }
            if (email !== "") {
                let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!pattern.test(email)) {
                    setErrorMsg("Invalid email format.");
                    return;
                }
            }
            if (phone === '') {
                setErrorMsg("Phone can't be empty.")
                return;
            }

            let tempEmail = email
            tempEmail = tempEmail.toLowerCase();
            const data = {
                "name": name,
                "email": tempEmail,
                "phone": phone,
                "destination": tripsData.country.name,
                "send_me_updates": update
            };
           // console.log('asdfghjhgfdsa',tripsData);
            //console.log('asdfghjhgfdsa',data);
            
            setLoader(true)
            const response = await post(BASE_URL + '/contact/share_details', data)
            if (response && response.data && response.data.status == 'ok') {
                setName("")
                setEmail("")
                setPhone("")
                setUpdate(false)
                notify()
                setLoader(false);
            }
            else {
                setLoader(false);
                setErrorMsg('Something went wrong, please try again later.')
            }
        } catch (ex: any) {
            setLoader(false);
            setErrorMsg(ex);
        }
    }


    const notify = () => {
        toast.success('Thank you for sharing your details.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };

    return (
            <ClickAwayListener onClickAway={()=>{setErrorMsg("")}}>
        <div className="TripDetailRightSectionMainDiv">
            <div className="TripDetailRightSectionFirstDiv">
                <div className="TripDetailRightSectionFirstDivHead">
                    Kindly share your details <br /> we'll be in touch shortly!
                </div>
            </div>
            <div className="TripDetailRightSectionDotsDiv"></div>
            <div className="TripDetailRightSectionFirstDiv" style={{ paddingTop: 0 }}>
                <div className="TripDetailRightSectionInputContainer">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        onFocus={() => { setErrorMsg("") }}
                    />
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        onFocus={() => { setErrorMsg("") }}
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        onFocus={() => { setErrorMsg("") }}
                    />
                </div>
                {errorMsg != '' && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: '1vw',
                                marginTop:'1vw',
                                marginBottom:'-1.5vw',
                            }}
                        >
                            {errorMsg}
                        </div>
                    )}
                    {!loader ?
                <div className="TripDetailRightSectionSendBtn clickableBlackBtn" onClick={() => { sendDetails() }}>
                    SEND
                </div>
                :
                <div className="TripDetailRightSectionSendBtn clickableBlackBtn" style={{opacity:0.7}}>
                    SEND...
                </div>
            }
                <div className="TripDetailRightOfferCheckDiv">
                    <input type="checkbox" checked={update} onChange={(e) => {setUpdate(e.target.checked)}}
                        onFocus={() => {setErrorMsg("") }} />
                    <span>Yes, send me updates and <br /> advertising offers</span>
                </div>
            </div>
        </div>
        </ClickAwayListener>
    )
}
export default TripDetailRightSection;
