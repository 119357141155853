import React, { useState, useEffect, useRef } from "react";
import "./TripPage.css";
import { useNavigate, useLocation } from 'react-router-dom';
import YellowDot from '../../assets/yellowDot.png'
import TcArrowIcon from "../../assets/tcArrowIcon.png"
import MapBox from "./Map/MapBox";
import PriceCard from"./PriceCard";

interface IProps {
    tripsData: any;
}


const TripDetailLeftSection = ({ tripsData }: IProps) => {
    console.log('tripsData', tripsData)
    const navigate = useNavigate();

    return (
        <div className="TripDetailLeftSectionMainDiv">

            <div className="TripDetailLeftSectionIntro">
                {/* An experiential jeep trip in mountainous Greece combines ancient stone bridges, powerful forests, coffee in front of alpine landscapes, crossing canyons and mountain passes and rafting in wild rivers, small and authentic stone villages in Zagoria where you can taste dolmats and mosca soufleki and lamb chops, the phenomenon of meteor. */}
                {tripsData.description}
            </div>

{tripsData && tripsData.highlights && tripsData.highlights.length>0 &&
            <div className="tripDetailHighlightDiv">
                <div className="tripDetailHighlightHead" >
                    Highlights of your trip
                </div>
                <div className="tripDetailHighlightItem">
                    {tripsData.highlights.map((highlight:any,index:any)=>(
                    <div>
                        <div>
                            <img src={YellowDot} alt="" />
                            <span>{highlight}</span>
                        </div>

                    </div>
                        ))}
               </div>
            </div>

}
          <div className="tripDetailMapDiv">
                <MapBox tripsData={tripsData} />
            </div>

            {tripsData && tripsData.days && tripsData.days.length > 0 &&
                <div>
                    <div className="tripDetailDayByDayHead">
                        Day-by-Day
                    </div>

                    {tripsData.days.map((day: any, index: any) => (
                        <div>
                            <div className="tripDaysDBDHead">
                                <span style={{ fontFamily: 'interBold' }}>Day {index + 1}</span>
                                <span className="tripDaysDBDHeadBorder"></span>
                                <span style={{ fontFamily: 'interSemiBold' }}>{day.name}</span>
                            </div>
                            <div className="TripDetailLeftDayDetail">
                                <div>
                                    {/* Flight to Thessaloniki, receiving the jeeps and driving towards the village of Nympao, visiting the bear farm and continuing by road to Lake Kastoria for overnight at the lakeside hotel. Overnight at Lake Kastoria. */}
                                    {day.description}
                                </div>
                                {day && day.pois_gpxs_data && day.pois_gpxs_data.length > 0 &&
                                    <ul>
                                        {day.pois_gpxs_data.map((poi:any, i:any) => (
                                            <li><span style={{fontWeight:"600"}}>{poi.name}</span><br/>{poi.description}</li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    ))}


                </div>
            }






            {/* {tripsData && tripsData.currency && tripsData.currency.id && */}
                <div className="tripDetailPriceRegisterDiv">
                    <div className="tripDetailPrice">
                        $ 100
                      {/*   {tripsData.currency.symbol}&nbsp;{tripsData.price} */}
                    </div>
                    <ul>
                        <li>
                            The price is per person in a double room
                        </li>
                        <li>
                            The price is per person based on 4 people in a jeep
                        </li>
                    </ul>
                    <div className="tripDetailRegistrationBtn clickableBtn" onClick={() => { navigate('/register') }}>
                        Registration form
                    </div>
                </div>

           {/*  } */}
            <div className="tripDetailIncludeExcludeDiv">
                <div style={{ marginBottom: '2.0625vw' }}>
                    <div className="tripDetailIncludeExcludeHead">Trip Included</div>
                    <ul className="tripDetailIncludeExcludeList">
                        <li>flights</li>
                        <li>Travel insurance and personal baggage suitable for the nature of the activity</li>
                        <li>Deductible reimbursement insurance in case of damage to the Jeep</li>
                        <li>fuel</li>
                        <li>Hotel tax of 1 euro per person per night in each hotel</li>
                        <li>Summer and holiday supplement - €95</li>
                        <li>Personal expenses (soft drinks at meals, souvenirs, etc.).</li>
                        <li>Tips for local service providers</li>
                        <li>Activities and attractions that do not appear under the "price includes" section</li>
                    </ul>
                </div>
                <div >
                    <div className="tripDetailIncludeExcludeHead">Trip Excluded</div>
                    <ul className="tripDetailIncludeExcludeList">
                        <li>flights</li>
                        <li>Travel insurance and personal baggage suitable for the nature of the activity</li>
                        <li>Deductible reimbursement insurance in case of damage to the Jeep</li>
                        <li>fuel</li>
                        <li>Hotel tax of 1 euro per person per night in each hotel</li>
                        <li>Summer and holiday supplement - €95</li>
                        <li>Personal expenses (soft drinks at meals, souvenirs, etc.).</li>
                        <li>Tips for local service providers</li>
                        <li>Activities and attractions that do not appear under the "price includes" section</li>
                    </ul>
                </div>

            </div>

            <div className="tripDetailTandCDiv clickableBtn">
                <span>Terms and conditions</span>
                <img src={TcArrowIcon} className="TcArrowIcon" alt="" />
            </div>

        </div>
    )
}
export default TripDetailLeftSection;
