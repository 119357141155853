import React, { useState, useEffect, useRef } from "react";
import "./Page.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const Sustainability = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header />
            <VideoPage pageFrom={'Sustainability'} countryname={""} />
            <div className="destinationPathRow" id="SustainabilityPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Sustainability </span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="mainComponent">

                <div className="MainHeading">
                    Sustainability & Responsible Travel
                </div>
                <div className="selfDriveBenefitsItems" style={{ textAlign: 'justify' }}>
                    <div>
                        {/* <span className="subHeading">Flexibility and Independence:</span>&nbsp; */}
                        <p className="description">At Indie4x4 by Medraft, nature isn’t just our passion—it’s the heart of everything we do. As specialists in nature and cultural journeys, we are deeply committed to responsible travel, ensuring that our adventures leave a positive impact on both the environment and the communities we visit.
                        </p>


                    </div>
                    <div>
                        <div className="Heading">
                            Our Core Principles:
                        </div>

                        <span className="subHeading">Respect for Nature – </span>&nbsp;
                        <span className="description">We drive only on designated and permitted trails, which exist primarily to serve local communities. This helps protect fragile ecosystems while still allowing travelers to experience nature at its best.
                        </span>


                    </div>

                    <div>
                        <span className="subHeading"> Supporting Local Communities – </span>&nbsp;
                        <span className="description">We prioritize direct partnerships with local businesses, ensuring that most trip components—accommodations, activities, and experiences—are purchased directly from the people who call these destinations home.
                            <br></br>
                            By traveling with Indie4x4, you’re not just exploring the world—you’re helping to preserve it.
                        </span>


                    </div>






                </div>

            </div>

            {/*  <GallerySection /> */}
            <div style={{ height: '6.625vw' }}></div>
            <Footer />
        </div>
    )
}
export default Sustainability;
