import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from 'react-spring';
import "./LandingPage.css";
import VideoPage from "./VideoSection";
import MobileWithHand from "../../assets/mobile_with_hand.png"
import DestinationsSection from "./DestinationsSection";
import MostPopular from "./MostPopular";
import IndieGuide from "./IndieGuide";
import Headline from "./Headline";
import TravelWithIndie from "./TravelWithIndie";
import Care from "./Care";
import LocalExpert from "./LocalExpert";
import ShareYourDetails from "./ShareYourDetails";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";
import { useLocation } from "react-router-dom";
const LandingPage = () => {
  const location = useLocation();
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isAnimatedFlip, setIsAnimatedFlip] = useState(false);
  
  const destinationRef = useRef(null);
  const shareDetailsRef = useRef(null);



  useEffect(() => {
    // Wait for the page to load, then scroll to the div
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.replace("#", ""));
        if (element) {
          console.log('asdfghj',element)
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Small delay ensures the page is fully rendered before scrolling
    }
  }, [location]);


  
  useEffect(() => {
    function updateSize() {
      setMobileView(window.innerWidth <= 768);
    }

    window.addEventListener("resize", updateSize);
    
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  // **Desktop: Scroll-based animations**
  useEffect(() => {
    if (!mobileView) {
      const updateScroll = () => {
        setIsAnimated(window.scrollY > 300);
        setIsAnimatedFlip(window.scrollY > 4500);
      }

      window.addEventListener('scroll', updateScroll);
      
      return () => {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  }, [mobileView]);

  // **Mobile: Intersection Observer for animations**
  useEffect(() => {
    if (mobileView) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.target === destinationRef.current) {
                setIsAnimated(true);
              }
              if (entry.target === shareDetailsRef.current) {
                setIsAnimatedFlip(true);
              }
            }
          });
        },
        { threshold: 0.3 }
      );

      if (destinationRef.current) observer.observe(destinationRef.current);
      if (shareDetailsRef.current) observer.observe(shareDetailsRef.current);

      return () => {
        if (destinationRef.current) observer.unobserve(destinationRef.current);
        if (shareDetailsRef.current) observer.unobserve(shareDetailsRef.current);
      };
    }
  }, [mobileView]);

  // Define animations
  const animation = useSpring({
    opacity: isAnimated ? 1 : 0,
    transform: `scale(${isAnimated ? 1 : 0.5})`,
    config: { tension: 200, friction: 20 }
  });

  const animationFlip = useSpring({
    opacity: isAnimatedFlip ? 1 : 0,
    transform: `rotateX(${isAnimatedFlip ? '0deg' : '180deg'})`,
    config: { tension: 150, friction: 15 }
  });

  return (
    <div>
      <Header />
      <VideoPage pageFrom={'landing'} countryname={""} />
      
      {!mobileView && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: "-23.875vw" }}>
          <img src={MobileWithHand} alt="Mobile with Hand" style={{ width: '22.375vw', height: '30.125vw', position: 'relative', zIndex: 1 }} />
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: "0.3125vw", marginTop: mobileView ? '2vw' : "-5vw" }} id="landingPageId">
        {!mobileView ? (
          <>
            <div style={{ color: '#3E2A1A', fontSize: "2.92vw", fontWeight: '600', fontFamily: 'poppinsSemiBold' }} id="destination">Enjoy 4X4 Self-Drive with iNDiE GPS Guide</div>
            <div style={{ color: '#3E2A1A', fontSize: "1.5vw", fontWeight: '600', fontFamily: 'interSemiBold' }}>Self-guided Packages for the Independent Explorers</div>
          </>
        ) : (
          <div style={{ padding: '0vw 5vw' }}>
            <div style={{ color: '#3E2A1A', fontSize: "6vw", fontWeight: '600', fontFamily: 'poppinsSemiBold', textAlign: 'center' }} id="destination">Enjoy 4X4 Self-Drive with iNDiE GPS Guide</div>
            <div style={{ color: '#3E2A1A', fontSize: "3.5vw", fontWeight: '600', fontFamily: 'interSemiBold', textAlign: 'center', marginTop: '1vw' }}>Self-guided Packages for the Independent Explorers</div>
          </div>
        )}
      </div>

      {/* Attach ref for Intersection Observer on mobile */}
      <animated.div style={animation} ref={mobileView ? destinationRef : null}>
        <DestinationsSection />
      </animated.div>

      <MostPopular pageFrom={'landing'} />
      <IndieGuide />
      <Headline />
      <TravelWithIndie />
      <Care />
      <LocalExpert />

      {/* Attach ref for Intersection Observer on mobile */}
      <animated.div style={animationFlip} ref={mobileView ? shareDetailsRef : null}>
        <ShareYourDetails />
      </animated.div>

      <Footer />
    </div>
  );
};

export default LandingPage;
