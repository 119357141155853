import React, { useState, useEffect } from "react";

import Header from "../../GeneralComponents/Header";

import Footer from "../../GeneralComponents/Footer";

//import './New.css'
const ThankYou = () => {

  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);


  return (
    <div>
      <Header />


      <div /* className={`${scrollY>200?"wow bounceInUp":""}`} */ className="wow bounceInUp">
        <div className="ContactUsFormDiv" style={{ marginTop: 140 }}>


          <form  >

            <div style={{ fontSize: mobileView ? 20 : 35, fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', marginBottom: 50, marginTop: 100 }}>
              Password was changed successfully
            </div>


          </form>


        </div>
      </div>
      <div style={{ height: '6.625vw' }}></div>

      <Footer />
    </div>
  )
}
export default ThankYou;
