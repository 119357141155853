import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import DesJeepicon from "../../assets/desJeepicon.png"
import { countries } from "../../GeneralComponents/Countries";
import { useNavigate } from 'react-router-dom';
import { BASE_URL, get } from "../../services/Calls";

const DestinationsSection = () => {
  const navigate = useNavigate();
    const [countriesData,setcountriesData] = useState([]);

    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);



    useEffect(() => {
      getCountries()
    }, [])
  
    const getCountries = async () => {
      try{
      const response = await get(BASE_URL + '/countries?order=ASC')
      if (response && response.length > 0) {
        setcountriesData(response)
      }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }
  return (
    <div >
   <div className="destinationsMainContainer" id='destinationPageId'>
<div className="destinationsHeadingdiv">
<img src={DesJeepicon} />
<div>Destinations</div>
</div>
<div className="destinationsGridView">
    {countriesData && countriesData.length>0 && countriesData.map((country:any,index:any)=>(
<div className="destinationsItemDiv" style={{backgroundImage:`url(${country.imageURL})`}} onClick={()=>{window.scroll(0,0); navigate('/'+country.name,{state:{country:country}})}}>
<div className="destinationsItemOverlay">
<div className="destinationsCountryName">
{country.name}
</div>
<div className="destinationsViewBtn clickableBtn" onClick={()=>{window.scroll(0,0);navigate('/'+country.name,{state:{country:country}})}}>
View 
</div>
</div>
</div>
))}
</div>
   </div>
    </div>
  )
}
export default DestinationsSection;
