import React, { useState, useEffect, useRef } from "react";
import "./Page.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const HowItWorks = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header />
            <VideoPage pageFrom={'HowItWorks'} countryname={""} />
            <div className="destinationPathRow" id="howItWorksPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">How It Works</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="mainComponent">

                <div className="MainHeading">
                    How It Works
                </div>
                <div className="selfDriveBenefitsItems" style={{textAlign:'justify'}}>
                    <div >
                        {/* <span className="subHeading">Flexibility and Independence:</span>&nbsp; */}
                        <p className="description">Planning your dream 4x4 self-drive adventure with Indie4x4 is easy and flexible. Follow these simple steps to create a seamless, unforgettable journey:
                        </p>

                    </div>
                    <div>

                        <div className="Heading">
                            Step-by-Step Guide to Your Indie4x4 Experience
                        </div>

                        <span className="subHeading"> Choose Your Destination & Itinerary – </span>&nbsp;
                        <span className="description">Pick from our curated routes or customize your trip with the help of our travel experts—free of charge.
                        </span>
                    </div>
                    <div>
                        <span className="subHeading">All-Inclusive Travel Package – </span>&nbsp;
                        <span className="description">Your trip typically includes a well-equipped 4x4 vehicle, comfortable hotels, and pre-booked activities and attractions.
                        </span>
                    </div>
                    <div>
                        <span className="subHeading">Indie App: Your Digital Travel Companion – </span>&nbsp;
                        <span className="description">Get full offline navigation, road and off-road routes, guided insights by location, hiking trails, trip documents, and more—all tailored to your itinerary.
                        </span>
                    </div>
                    <div>
                        <span className="subHeading">Expert Support, Anytime – </span>&nbsp;
                        <p className="description">Our local team is available before and during your trip, providing continuous assistance whenever you need it.
                        </p>
                        <p className="description">
                            With Indie4x4, adventure meets convenience—just pick your route, start your engine, and let the journey begin!
                        </p>
                    </div>

                </div>

            </div>

            {/*  <GallerySection /> */}
            <div style={{ height: '6.625vw' }}></div>
            <Footer />
        </div>
    )
}
export default HowItWorks;
