
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import 'bootstrap/dist/css/bootstrap.css'; 
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.css';
import 'wowjs/css/libs/animate.css';
import WOW from 'wowjs';
import LandingPage from "./Components/LandingFolder/LandingPage";
import Header from "./GeneralComponents/Header";
import Footer from "./GeneralComponents/Footer";
import DestinationsPage from "./Components/Destinations/DestinationsPage";
import TripPage from "./Components/Trip/TripPage";
import SelfDrive from "./Components/SelfDrive/SelfDrive";
import Register from "./GeneralComponents/Register";
import ContactUs from "./Components/ContactUs/ContactUs";
import DeleteUserData from "./Components/DeleteUserData/DeleteUserData";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ThankYou from "./Components/ForgotPassword/ThankYou";
import { ToastContainer, toast } from 'react-toastify';
import Extra from "./Components/Extra";
import { BASE_URL, get } from "./services/Calls";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermOfUse from "./Components/TermOfUse";
import HowItWorks from "./Components/Pages/HowItWorks";
import OurStory from "./Components/Pages/OurStory";
import Sustainability from "./Components/Pages/Sustainability";
import Destination from "./Components/Pages/Destination";
import OurTeam from "./Components/Pages/OurTeam";
import TermsAndConditions from "./Components/Pages/TermsAndConditions";
import SiteMap from "./Components/Pages/SiteMap";
import CancellationPolicy from "./Components/Pages/CancellationPolicy";



const App = () => {
  

  useEffect(() => {
    new WOW.WOW({
      live: false
  }).init();
}, []);

const [countriesData,setcountriesData] = useState([]);

useEffect(() => {
  getCountries()
}, [])

const getCountries = async () => {
  try{
  const response = await get(BASE_URL + '/countries?order=ASC')
  if (response && response.length > 0) {
    setcountriesData(response)
  }
}
catch(ex){
    //alert(ex)
console.log('error',ex)
}
}


  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<LandingPage />}/>
    <Route path="/register" element={<Register />}/>
    {countriesData && countriesData.length>0 && countriesData.map((country:any,index)=>(
      <>
    <Route path={'/'+country.name } element={<DestinationsPage />}/>
    <Route path={'/'+country.name+'/*' } element={<TripPage />}/>
    </>
    ))}

    {/* <Route path="/Trip" element={<TripPage />}/> */}
    <Route path="/SelfDrive" element={<SelfDrive />}/>
   
    <Route path="/ContactUs" element={<ContactUs />}/>
    <Route path="/DeleteUserData" element={<DeleteUserData />}/>

    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}/>
    <Route path="/ThankYou" element={<ThankYou />}/>

    <Route
          path={"/reset-password/:token/:email"}
          
          element={ <ForgotPassword />}
        />

    <Route path="/TermsOfUse" element={<TermOfUse />}/>
    <Route path="/HowItWorks" element={<HowItWorks />}/>
    <Route path="/OurStory" element={<OurStory />}/>
    <Route path="/Sustainability" element={<Sustainability />}/>
    <Route path="/Destination" element={<Destination />}/>
    <Route path="/OurTeam" element={<OurTeam />}/>
    <Route path="/TermsAndConditions" element={<TermsAndConditions />}/>
    <Route path="/CancellationPolicy" element={<CancellationPolicy />}/>
    


    {/* <Route path="/Extra" element={<Extra />}/> */}
       </Routes>

       <ToastContainer/>
       </BrowserRouter>
      
  );
};

export default App;
