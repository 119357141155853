import React, { useEffect, useState, useRef } from "react";
import VideoSecTempBgImg from "../../assets/videoSecTempBgImg.png"
import DestinationsPageBgImg from "../../assets/destinationsPageBgImg.png"
import WhiteDownArrow from "../../assets/whiteDownArrow.png"
import SelfDriveBGImg from "../../assets/selfDriveBGImg.png"
import ContactUsBg from "../../assets/contactUsBg.jpeg"
import RegisterBgImg from "../../assets/registerBgImg.jpeg"
import PrivacyBgImg from "../../assets/careBgImg.png"
import MainVideo from '../../assets/BANNER-v2.mp4'

interface IProps {
  pageFrom: any;
  countryname: any;
}

const VideoPage = ({
  pageFrom,
  countryname
}: IProps) => {


  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);

  const scrollToElement = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div>
      {pageFrom == "landing" ?
        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', }} className="VideoSectionMainBgImg" >
          <video className='videoTag' autoPlay loop muted style={mobileView ? { width: '100%', height: '55vw', objectFit: 'cover' } : { width: '100%', height: '100%', objectFit: 'cover' }}>
            <source
              src={MainVideo}
              type="video/mp4"
            />
          </video>
          <div className="VideoSectionOverlay" style={{ position: 'absolute', zIndex: 0 }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('landingPageId') }} />
            </div>
          </div>
        </div>
        :
        <>
          {pageFrom == "contactUs" ?
            <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${ContactUsBg})` }} className="VideoSectionMainBgImg">
              <div className="VideoSectionOverlay">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                  <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                    Contact Us
                  </div>
                  <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('contactUsPageId') }} />
                </div>
              </div>
            </div>
            :
            <>
              {pageFrom == "register" ?
                <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${RegisterBgImg})` }} className="VideoSectionMainBgImg">
                  <div className="VideoSectionOverlay">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                      <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                        Register
                      </div>
                      <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('registerPageId') }} />
                    </div>
                  </div>
                </div>
                :
                <>
                  {pageFrom == "PrivacyPolicy" ?
                    <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${PrivacyBgImg})` }} className="VideoSectionMainBgImg">
                      <div className="VideoSectionOverlay">
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                          <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                            Privacy Policy
                          </div>
                          <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('PrivacyPageId') }} />
                        </div>
                      </div>
                    </div>
                    :
                <>
                  {pageFrom == "CancellationPolicy" ?
                    <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${PrivacyBgImg})` }} className="VideoSectionMainBgImg">
                      <div className="VideoSectionOverlay">
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                          <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                          Cancellation Policy
                          </div>
                          <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('CancellationPolicyPageId') }} />
                        </div>
                      </div>
                    </div>
                    :
                <>
                  {pageFrom == "SiteMap" ?
                    <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${PrivacyBgImg})` }} className="VideoSectionMainBgImg">
                      <div className="VideoSectionOverlay">
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                          <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                          Site Map
                          </div>
                          <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('SiteMapPageId') }} />
                        </div>
                      </div>
                    </div>
                    :
                    <>
                      {pageFrom == "TermOfUse" ?
                        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${PrivacyBgImg})` }} className="VideoSectionMainBgImg">
                          <div className="VideoSectionOverlay">
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                              <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                Terms Of Use
                              </div>
                              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('TermOfUsePageId') }} />
                            </div>
                          </div>
                        </div>
                        :
                    <>
                      {pageFrom == "TermsAndConditions" ?
                        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${PrivacyBgImg})` }} className="VideoSectionMainBgImg">
                          <div className="VideoSectionOverlay">
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                              <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                              Terms And Conditions
                              </div>
                              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('TermOfUsePageId') }} />
                            </div>
                          </div>
                        </div>
                        :
                        <>
                          {pageFrom == "HowItWorks" ?
                            <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${SelfDriveBGImg})` }} className="VideoSectionMainBgImg">
                              <div className="VideoSectionOverlay">
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                  <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                    {"How It Works"}
                                  </div>
                                  <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('howItWorksPageId') }} />
                                </div>
                              </div>
                            </div>
                            :
                            <>
                              {pageFrom == "OurStory" ?
                                <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${SelfDriveBGImg})` }} className="VideoSectionMainBgImg">
                                  <div className="VideoSectionOverlay">
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                      <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                        {"Our Story"}
                                      </div>
                                      <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('OurStoryPageId') }} />
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {pageFrom == "Sustainability" ?
                                    <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${SelfDriveBGImg})` }} className="VideoSectionMainBgImg">
                                      <div className="VideoSectionOverlay">
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                          <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                            {"Sustainability"}
                                          </div>
                                          <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('SustainabilityPageId') }} />
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <>
                                      {pageFrom == "OurTeam" ?
                                        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${ContactUsBg})` }} className="VideoSectionMainBgImg">
                                          <div className="VideoSectionOverlay">
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                              <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                                {"Our Team"}
                                              </div>
                                              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('OurTeamPageId') }} />
                                            </div>
                                          </div>
                                        </div>
                                        :
                                    <>
                                      {pageFrom == "Destination" ?
                                        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${ContactUsBg})` }} className="VideoSectionMainBgImg">
                                          <div className="VideoSectionOverlay">
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                              <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                                {"Destination"}
                                              </div>
                                              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { scrollToElement('DestinationPageId') }} />
                                            </div>
                                          </div>
                                        </div>
                                        :

                                        <div style={{ height: mobileView ? '55vw' : '36vw', width: '100%', backgroundImage: `url(${pageFrom == "slefDrive" ? SelfDriveBGImg : DestinationsPageBgImg})` }} className="VideoSectionMainBgImg">
                                          <div className="VideoSectionOverlay">
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.875vw' }}>
                                              <div style={{ fontSize: mobileView ? '7vw' : '3.75vw', fontWeight: '800', fontFamily: 'interBold', textAlign: 'center', color: '#fff' }}>
                                                {pageFrom == "slefDrive" ? "About Self-drive 4x4" : countryname}
                                              </div>
                                              <img src={WhiteDownArrow} style={{ cursor: 'pointer', width: mobileView ? '5vw' : '2.375vw', height: mobileView ? '2.5vw' : '1.25vw' }} onClick={() => { pageFrom == "slefDrive" ? scrollToElement('selfDrivePageId') : scrollToElement('destinationPageId') }} />
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  }
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </>
          }
        </>
          }
        </>
          }
        </>
          }
        </>
      }
    </div>
  );
};

export default VideoPage;
