import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import HeadlineRatingStar from "../../assets/headlineRatingStar.png"

const Headline = () => {

    return (
        <div className="HeadlineMainContainer">
        <div className="HeadlineInnerContainer">
            <div className="HeadlineMainHeadDiv">Testimonials</div>
        </div>
        <div style={{width:'100%'}}>
        <div className="HeadlineItemDiv">
<div>
    <img src={HeadlineRatingStar} alt=""  />
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <span className="HeadlineItemPersonName">Judi  Y.</span>
    <span className="HeadlineItemGoogle">Google</span>
    </div>
    <div>
    "In recent years, we've exclusively traveled with Medraft, from the enchanting Lapland to captivating destinations like Greece, Portugal, and the Emirates. Each trip is like stepping into a nature movie, filled with rich experiences, attractions, and everything you need to truly savor the places you visit. They excel in paying attention to even the smallest details."
    </div>
</div>
<div>
    <img src={HeadlineRatingStar} alt="" />
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <span className="HeadlineItemPersonName">Efrat B.</span>
    <span className="HeadlineItemGoogle">Google</span>
    </div>
    <div>
    "I had an incredible experience with Medraft on a trip to Montenegro. It was fun, professional, diverse, and truly enriching. It even felt life-changing. This was my first organized trip, and it won't be my last. A big thank you to the fantastic Madraft team!"
    </div>
</div>
<div>
    <img src={HeadlineRatingStar} alt="" />
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <span className="HeadlineItemPersonName">Noga G.</span>
    <span className="HeadlineItemGoogle">Google</span>
    </div>
    <div>
    "For several years, we've chosen Medraft for our journeys to unique and alluring destinations. Their jeeps take us to places buses can't reach, unveiling hidden gems. The company is exceptionally organized, with top-notch guides who cater to every request. We've traveled with them before and will continue to do so. They're true champions!"
    </div>
</div>

        </div>
        </div>
        </div>
    )
}
export default Headline;
