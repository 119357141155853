import React, { useState, useEffect, useRef } from "react";
import "./Page.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";
import DestinationsSection from "../LandingFolder/DestinationsSection";
const Destination = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header />
            <VideoPage pageFrom={'Destination'} countryname={""} />
            <div className="destinationPathRow" id="destinationPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Destination </span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="mainComponent" style={{width:'auto'}}>

            <div style={{ padding: '0vw 5vw' }}>
            <div style={{ color: '#3E2A1A', fontSize: "3vw", fontWeight: '600', fontFamily: 'poppinsSemiBold', textAlign: 'center' }} id="destination">Enjoy 4X4 Self-Drive with iNDiE GPS Guide</div>
            <div style={{ color: '#3E2A1A', fontSize: "1.7vw", fontWeight: '600', fontFamily: 'interSemiBold', textAlign: 'center', marginTop: '1vw' }}>Self-guided Packages for the Independent Explorers</div>
          </div>
               
          <DestinationsSection />
            </div>

            {/*  <GallerySection /> */}
            <div style={{ height: '6.625vw' }}></div>
            <Footer />
        </div>
    )
}
export default Destination;
