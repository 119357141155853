import React, { useState, useEffect, useRef } from "react";
import "./Page.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";
import LocalExpert from "../LandingFolder/LocalExpert";

const OurTeam = () => {

    const navigate = useNavigate();
    const location = useLocation();

  
   


    const teamMembers = [
        {
          name: "mer Flum",
          role: "The leading force behind PlanYourTrip",
          image: "https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/new_omer_flum_person_1.jpeg",
        },
        {
          name: "V Globin",
          role: "Our Chief Trip Planning Officer",
          image: "https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/new_TsachiGlobin.png",
        },
        {
          name: "mer Flum",
          role: "The leading force behind PlanYourTrip",
          image: "https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/new_omer_flum_person_1.jpeg",
        },
        {
          name: "V Globin",
          role: "Our Chief Trip Planning Officer",
          image: "https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/new_TsachiGlobin.png",
        },
        
      ];
      


    return (
        <div>
            <Header />
            <VideoPage pageFrom={'OurTeam'} countryname={""} />
            <div className="destinationPathRow" id="OurTeamPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Our Team</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="mainComponent" style={{width:'auto'}}>

            <div className="team-container">
      <h2 className="team-title">Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-card">
            <img src={member.image} alt={member.name} className="team-image" />
            <h3 className="team-name">{member.name}</h3>
            <p className="team-role">{member.role}</p>
          </div>
        ))}
      </div>
    </div>
                    </div>


           
            {/*  <GallerySection /> */}
           {/*  <div style={{ height: '6.625vw' }}></div> */}
            <Footer />
        </div>
    )
}
export default OurTeam;
