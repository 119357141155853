import React, { useState, useEffect, useRef } from "react";
import "./Page.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const OurStory = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header />
            <VideoPage pageFrom={'OurStory'} countryname={""} />
            <div className="destinationPathRow" id="OurStoryPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Our Story</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="mainComponent">

                <div className="MainHeading">
                    Our Story
                </div>
                <div className="selfDriveBenefitsItems" style={{textAlign:'justify'}}>
                    <div>
                        {/* <span className="subHeading">Flexibility and Independence:</span>&nbsp; */}
                        <p className="description">Indie4x4 was founded in 2012 as a branch of Medraft Nature and Adventure, a world leader in off-road 4x4 adventures. At that time, the only way to truly experience the thrill of off-road exploration was with qualified, experienced guides due to the deep knowledge required of remote tracks. Our founder, Omer Flum, recognized that many nature and culture enthusiasts could enjoy these adventures on their own terms, at their own pace, without compromising on the experience.
                        </p>
                        <p className="description">
                            Over the years, we’ve developed a unique app and a network of local experts, enabling travelers to explore the best natural landscapes independently. Today, we offer unforgettable off-road journeys in over 20 destinations worldwide, empowering adventurers to immerse themselves in nature, all while discovering new cultures, and forging unforgettable memories.
                        </p>

                    </div>


                </div>

            </div>

            {/*  <GallerySection /> */}
            <div style={{ height: '6.625vw' }}></div>
            <Footer />
        </div>
    )
}
export default OurStory;
