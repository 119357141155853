import React, { useEffect, useState } from "react";
import '../PrivacyPolicy.css'
import styled from "styled-components";
import Header from "../../GeneralComponents/Header";
import VideoPage from "../LandingFolder/VideoSection";
import Footer from "../../GeneralComponents/Footer";
const Content = styled.p`
  //   overflow-y: scroll;
  font-size: small;
  max-width: 80%;
  margin: 0 auto;
  padding: 30px 0px;
`;

const TermsAndConditions = () => {
  const [mobileView, setMobileView] = useState<boolean>(
    window.innerWidth <= 768
  );

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
     <Header/>
     <VideoPage pageFrom={'TermsAndConditions'} countryname={""} />
{/* <div style={{fontSize:32,fontWeight:500,color:'#FAC809',textAlign:'center'}}>
Privacy Policy
</div> */}

<Content id="TermOfUsePageId">
        <p>
          These Terms of Use (&quot;Terms&quot;) constitute a binding legal
          agreement between Indie 4x4 by Medraft Ltd. (collectively,
          &quot;Indie 4x4 by Medraft&quot;, &quot;we&quot;, &quot;our&quot; or
          &quot;us&quot;) and our users (each, a &quot;User&quot;,
          &quot;you&quot; or &quot;your&quot;), and governs your access and use
          of all features, content, and other services provided by Indie 4x4 by Medraft,
          through our website https://indie4x4-frontend-4ec910c88de3.herokuapp.com/ (&quot;Website&quot;) and
          mobile application (&quot;Application&quot;; collectively
          &quot;Services&quot;). Any use of the Services, including any purchase
          of any products or features available through or Services, are
          governed by these Terms.
          <br />
          By using our Services, you affirmatively signify that you have read,
          understood, and agreed to be bound by these Terms and our Privacy
          Policy, which is incorporated herein by reference. If you do not agree
          to these Terms or to the Privacy Policy, you must not register an
          account or otherwise use or access the Services.
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>
            1. REGISTRATION AND USER ACCOUNT
          </span>
          <br />
          1.1 In order to make use of certain features of the Services, you may
          have to create an account (&quot;Account&quot;). You agree not to
          create an Account for anyone else or use the account of another
          without their permission. <br />
          1.2 When creating your Account, you must provide accurate and complete
          information. <br />
          1.3 The Account is personal, and should not serve any other person but
          the User. <br />
          1.4 You are solely responsible for the activity that occurs in your
          Account, and you must keep your Account password secure. You must
          notify us immediately of any breach of security or unauthorized use of
          your Account. <br />
          1.5 You may delete your Account at any time, provided that we may
          retain certain information as required to comply with our legal
          obligations, resolve disputes and enforce our agreements (unless we
          are instructed otherwise). If you wish to delete your Account you may
          send an email request to us at the contact details below.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            2. USER OBLIGATIONS AND RESTRICTIONS.
          </span>
          <br />
          2.1 Obligations. You agree to do each of the following in connection
          with your use of the Services: (i) comply with all applicable laws,
          rules and regulations, including those regarding data protection and
          privacy, intellectual property rights and export control; and (ii) pay
          the fees for the Services, if applicable, when due.
          <br />
          2.2 Restrictions. You shall not (and shall not permit or encourage any
          third party to) do any of the following: (a) reproduce, mirror, or
          frame the Service; (b) sell, assign, lease, lend, rent, distribute, or
          make available the Service to any third party, or otherwise offer or
          use the Services in a time-sharing, outsourcing, or service bureau
          environment; (c) modify, alter, adapt, arrange, translate, decompile,
          disassemble, reverse engineer, decrypt, or otherwise attempt to
          discover the source code or non-literal aspects (such as the
          underlying ideas, algorithms, structure, sequence, organization, and
          interfaces) of, the Service; (d) remove, alter, or conceal, in whole
          or in part, any copyright, trademark, or other proprietary rights
          notice or legend displayed or contained on or in the Services; (e)
          circumvent, disable or otherwise interfere with security-related or
          technical features or protocols of the Service; (f) make a derivative
          work of the Service, or use the Services to develop any service or
          product that is the same as (or substantially similar to or
          competitive with) the Service; (g) publish or transmit any robot,
          virus, malware, Trojan horse, spyware, or similar malicious item
          intended (or that has the potential) to damage or disrupt the Service;
          (i) take any action that imposes or may impose (at 's sole discretion)
          an unreasonable or disproportionately large load on the Services
          infrastructure, or otherwise interfere (or attempt to interfere) with
          the integrity or proper working of the Service; (j) use the Services
          to infringe, misappropriate or violate any third party&rsquo;s
          Intellectual Property Rights, or any Law; (k) use the Services or any
          part thereof, in any fraudulent or unlawful manner, or in breach of
          these Terms.
          <br />
          2.3 User Responsibility. When using the Services, you must act
          responsibly and exercise good judgment. This means, among other
          things, that any content uploaded/written/submitted by you, including
          but not limited to when you interact with a third party via our Local
          Hero feature, you may not: (i) violate these Terms, or any applicable
          law or regulation; (ii) infringe the rights of any third party,
          including but not limited to, intellectual property, privacy,
          publicity or contractual rights; (iii) except as expressly permitted
          herein, use the Services for any commercial purpose whatsoever; (iv)
          impersonate any person or entity, or falsify or otherwise misrepresent
          yourself or your association with any person or entity, whether by
          providing or omitting to provide information; (v) interfere or damage
          the Services by, inter alia, the use of viruses, cancel bots, Trojan
          horses, harmful codes, flood pings, denial-of-service attacks or
          similar methods or technologies; or (vi) contain Abusive Content (as
          defined below). You understand and agree that the Company may (but is
          not obligated to) review and remove or block any Abusive Content of
          any kind. <br />
          &quot;Abusive Content&quot;, for the purpose of these Terms, refers to
          any content, which: (i) is defamatory; (ii) contains nudity or
          sexually explicit content; (iii) disparages any ethnic, racial, sexual
          or religious group by stereotypical portrayal or otherwise; (iv) makes
          use of offensive language or images or which violates the rights of,
          harms, or threatens the safety of third parties or other users of the
          Service. <br />
          2.4. In the event that a User uses the Services for any purpose
          contrary to any applicable law and/or these Terms, then without
          derogating from any other remedy that the Company may be entitled to
          remove the User's Account from the Services, and the User will not
          have any claim and/or suit against the Company due to any damages that
          may thus arise. For the avoidance of doubt, revoking a User's access
          to the Services shall not exempt the User from any of his liabilities
          towards the Company or towards any third party resulting from the
          User's use of the Services.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>3. MINORS</span>
          <br />
          3.1 The Services are not intended for users under the age of 18, and
          Indie 4x4 by Medraft does not knowingly collect personal information from or
          about individuals under the age of 18. Users are expressly prohibited
          from submitting personally identifiable information about individuals
          under 18 to us; any such information submitted by users will not
          knowingly be used, posted, or retained by us.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>4. CHARGES</span>
          <br />
          4.1 Using the Services. Certain services and features of the Services
          may be provided to you free-of-charge, while others require payment
          before you can use or access them, according to the prices published
          on the Website. We have the discretion to change the price of the
          offered services and features from time to time, and the published
          price on our Website at the time of the specific purchase, will apply.
          <br />
          4.2 Payment Policy. All information that you provide in connection
          with a purchase or transaction or other monetary transaction
          interaction with the Services must be accurate, complete, and current.
          You agree to pay all charges incurred by users of your credit card,
          debit card, or other payment method used in connection with a purchase
          or transaction or other monetary transaction interaction with the
          Services at the prices in effect when such charges are incurred. You
          confirm that you are permitted to use the payment method you chose and
          you authorize us and our designated payment processor to charge the
          full amount due for the applicable Services to the payment method you
          designate for the purchase. You will pay any applicable taxes relating
          to any such purchases, transactions or other monetary transaction
          interactions. Subject to Section 4.3 all purchases are final and no
          refunds or credits will be provided.
          <br />
          4.3 Cancellation and Refunds. If you terminate your Account with the
          Company, you shall not be entitled to a refund of any fees paid by you
          in connection with your Account (i.e. purchase of certain services and
          features provided by Company), unless otherwise required by applicable
          laws. However, if you feel that there are special circumstances,
          please contact us though the contact details below, and we will
          consider your request, subject to our sole discretion. Cancellations
          and refunds requested with respect to Supplier's Services, will be
          handled solely by Indie 4x4 by Medraft, and will be subject to each Supplier's
          terms of use.
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>5. THIRD PARTIES SERVICES</span>
          <br />
          5.1 Some of our services and features offer you to engage with the
          third parties (&quot;Suppliers&quot;) for their services through our
          Services (&quot;Supplier's Services&quot;). When you choose to
          purchase Supplier's Services through Indie 4x4 by Medraft Services, you agree
          that the services provided you by the Supplier will be governed by
          that Supplier's terms of use. You agree to send each query, complaint,
          or request (including requests for cancellation or refunds) you may
          have concerning to Supplier's Services you used or purchased though
          our Services, to our support team, at: support@Indie4x4.com and we
          will handle it vis-&agrave;-vis you and the relevant Supplier. <br />
          5.2 If you choose to use our &quot;Local Hero&quot; service, your
          contact details (i.e. name and email address) will be provided, in
          accordance with our Privacy Policy, to a local guide that will assist
          you in planning your vacation in your selected destination. The entire
          communication with the local guide must be made through the Website,
          and you shall not contact the local guide by any other means. <br />
          5.3 Certain services and features we provide through our Services
          contain our recommendations for planning your vacation. These
          recommendations reflect our opinion and are based on our sole
          discretion according to the preferences you provide us. Our
          recommendations may be changed from time to time. Any actions taken
          based on our recommendations are of your own free will and at your own
          risk. Accordingly, we recommend that each User conducts any and all
          required examinations before purchasing Supplier's Services. <br />
          5.4 Notwithstanding the foregoing, you acknowledge that the exclusive
          liability for examining the qualifications, price, and other
          characteristics of Supplier's Services, lies exclusively upon the
          User. The Company will not bear any liability or responsibility for
          any damages which may be caused to any User as a result of any
          discrepancy related to Supplier's Services.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>6. TRAVEL DESTINATIONS</span>
          <br />
          6.1 When you book international travel reservations with Suppliers or
          plan international trips using the Services, you are responsible for
          ensuring that you meet all foreign entry requirements and that your
          travel documents, including passports and visas, are in order.
          <br />
          6.2 For passport and visa requirements, please consult the relevant
          embassy or consulate for information. Because requirements may change
          at any time, be sure to check for up-to-date information before
          booking and departure. Indie 4x4 by Medraft accepts no liability for travelers
          who are refused entry onto a flight or into any country because of the
          traveler&rsquo;s failure to carry the travel documents required by any
          airline, authority, or country, including countries the traveler may
          just be passing through end route to his or her destination.
          <br />
          6.3 It is also your responsibility to consult your physician for
          current recommendations on inoculations before you travel
          internationally, and to ensure that you meet all health entry
          requirements and follow all medical guidance related to your trip.
          <br />
          6.4 BY LISTING INFORMATION RELEVANT TO TRAVEL TO PARTICULAR
          INTERNATIONAL DESTINATIONS, Indie 4x4 by Medraft DOES NOT REPRESENT OR WARRANT
          THAT TRAVEL TO SUCH POINTS IS ADVISABLE OR WITHOUT RISK, AND IS NOT
          LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT FROM TRAVEL TO SUCH
          DESTINATIONS.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            7. INTELLECTUAL PROPERTY RIGHTS
          </span>
          <br />
          7.1 The: (i) content on the Services, including without limitation,
          the text, descriptions, software, applications, source code, graphics,
          photos, sounds, videos, interactive features, and services
          (collectively, the &quot;Materials&quot;); and (ii) the trademarks,
          service marks and logos contained therein (&quot;Marks&quot;), are the
          property of Indie 4x4 by Medraft and/or its licensors and may be protected by
          applicable copyright or other intellectual property laws and treaties.
          &quot;Indie 4x4 by Medraft&quot;, the Indie 4x4 by Medraft logo, and other marks are
          Marks of Experience or its affiliates. All other trademarks, service
          marks, and logos used on the Services are the trademarks, service
          marks, or logos of their respective owners. We reserve all rights not
          expressly granted in and to the Website and the Application and the
          Materials. &quot;Intellectual Property Rights&quot; means any and all
          rights, titles and interests, whether foreign or domestic, in and to
          any and all trade secrets, patents, copyrights, service marks,
          trademarks, know-how, or similar intellectual property rights, as well
          as any and all moral rights, rights of privacy, publicity and similar
          rights of any type under the laws or regulations of any governmental,
          regulatory, or judicial authority, foreign or domestic. <br />
          7.2 To the extent you provide us any feedback, comments or suggestions
          (&quot;Feedback&quot;), you grant us a royalty-free, fully paid up,
          worldwide, perpetual and irrevocable license to incorporate the
          Feedback into the Services or any of our current or future products or
          services.
          <br />
          7.3 Materials on the Services is provided to you for your information
          and personal use only and may not be used, modified, copied,
          distributed, transmitted, broadcast, displayed, sold, licensed,
          de-compiled, or otherwise exploited for any other purposes whatsoever
          without our prior written consent. If you download or print a copy of
          the Materials you must retain all copyright and other proprietary
          notices contained therein.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>8. LINKS</span>
          <br />
          8.1 The Services may contain links, and may enable you to post
          content, to third party websites, including Suppliers, that are not
          owned or controlled by the Company. We are not affiliated with, have
          no control over, and assume no responsibility for the content, privacy
          policies, or practices of, any third party websites. You: (i) are
          solely responsible and liable for your use of and linking to third
          party websites and any content that you may send or post to a third
          party website; and (ii) expressly release the Company from any and all
          liability arising from your use of any third party website.
          Accordingly, we encourage you to read the terms and conditions and
          privacy policy of each third party website that you may choose to
          visit.
          <br />
          8.2 Indie 4x4 by Medraft permits you to link to the Website provided that: (i)
          you link to but do not replicate any page on this Website; (ii) the
          hyperlink text shall accurately describe the Content as it appears on
          the Website; (iii) you shall not misrepresent your relationship with
          Indie 4x4 by Medraft or present any false information about Indie 4x4 by Medraft and
          shall not imply in any way that we are endorsing any services or
          products, unless we have given you our express prior consent; (iv) you
          shall not link from a website (&quot;Third Party Website&quot;) which
          prohibits linking to third parties; (v) such Third party Website does
          not contain content that (a) is offensive or controversial (both at
          our discretion), or (b) infringes any intellectual property, privacy
          rights, or other rights of any person or entity; and/or (vi) you, and
          your website, comply with these Terms and applicable law.
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>9. PRIVACY</span>
          <br />
          We will use any personal information that we may collect or obtain in
          connection with the Services in accordance with our Privacy Policy,
          which is available at [add link].
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            10. APP STORES ADDITIONAL TERMS
          </span>
          <br />
          10.1. You acknowledge that these Terms are between you and Company and
          not with Apple Inc. (&quot;Apple&quot;) or Google Inc.
          (&quot;Google&quot;). The Google Play Store or the Apple App Store
          have their own terms and conditions to which you must agree to abide
          by before downloading Company Services from them. <br />
          10.2. You agree to comply with, and your license to use Company
          Services is conditioned upon your compliance with, all applicable
          terms and conditions of the Google Play Store or the Apple App Store.
          <br />
          10.3. Please note that the following terms apply in addition to all
          other provisions in this Agreement, if you downloaded Company Services
          to your mobile device from the Apple App Store or Google Play Store.
          <br />
          10.4. You acknowledge and agree that:
          <br />
          a. Apple has no obligation to furnish any maintenance and support
          services with respect to Company Services or handle any warranty
          claims.
          <br />
          b. Apple is not responsible for addressing any claims you have
          relating to Company Services, including product liability claims,
          consumer protection claims, intellectual property infringement claims,
          or any claim that Company Services fails to conform to any applicable
          legal or regulatory requirement.
          <br />
          c. Apple and Apple&rsquo;s subsidiaries, are third party beneficiaries
          of this Agreement and that, upon your acceptance of this Agreement,
          Apple will have the right (and will be deemed to have accepted the
          right) to enforce this Agreement against you as a third party
          beneficiary thereof.
          <br />
          d. In the event of any failure of the Company Services to conform to
          any applicable warranty, you may notify Apple, and Apple will refund
          the purchase price for the Company Services to you (if applicable) and
          to the maximum extent permitted by applicable law, and Apple will have
          no other warranty obligation whatsoever with respect to the Company
          Services.
          <br />
          e. Any claims, losses, liabilities, damages, costs or expenses
          attributable to any failure to conform to any warranty will be the
          sole responsibility of Company, in accordance with the provisions of
          this Agreement.
          <br />
          f. Apple shall not be responsible nor shall you refer to Apple any
          claim of any third party that the Company Services or your possession
          and use of the Company Services infringes that third party&rsquo;s
          intellectual property rights.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>11. WARRANTY DISCLAIMERS</span>
          <br />
          11.1 This section applies whether or not the services provided under
          the Services are for payment. Applicable law may not allow the
          exclusion of certain warranties, so to that extent certain exclusions
          set forth herein may not apply.
          <br />
          11.2 THE SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
          AVAILABLE&quot; BASIS, AND WITHOUT WARRANTIES OF ANY KIND EITHER
          EXPRESS OR IMPLIED. Indie 4x4 by Medraft HEREBY DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
          MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF
          DEALING OR USAGE OF TRADE. Indie 4x4 by Medraft DOES NOT GUARANTEE THAT THE
          SERVICES AND THE SUPPLIER'S SERVICES WILL BE FREE OF BUGS,
          MALFUNCTIONS, SECURITY BREACHES, VIRUS ATTACKS, OR ILLEGAL
          PENETRATIONS. THE SERVICES AND/OR THE SUPPLIER'S SERVICES MAY
          OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR
          OTHER REASONS. YOU AGREE THAT Indie 4x4 by Medraft WILL NOT BE HELD
          RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY
          RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS,
          TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS. WE DO NOT
          WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR SERVICE THAT IS
          FEATURED OR ADVERTISED ON THE WEBSITE BY A THIRD PARTY. Indie 4x4 by Medraft
          DOES NOT WARRANT THAT THE INFORMATION PROVIDED ON THE SERVICES IS
          ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE AND MAKES NO
          GUARANTEES ABOUT THE AVAILABILITY OF SPECIFIC PRODUCTS AND SERVICE.
          Indie 4x4 by Medraft MAY MAKE IMPROVEMENTS OR CHANGES TO THE SERVICES AT ANY
          TIME. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM THE SERVICES SHALL
          CREATE ANY WARRANTY OF ANY KIND.
          <br />
          11.3 THE SUPPLIERS ARE INDEPENDENT CONTRACTORS AND NOT AGENTS OR
          EMPLOYEES OF Indie 4x4 by Medraft. Indie 4x4 by Medraft IS NOT LIABLE FOR THE ACTS,
          ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE
          OF ANY SUCH SUPPLIERS OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY
          DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING THERE FROM.
          Indie 4x4 by Medraft HAS NO LIABILITY AND WILL MAKE NO REFUND IN THE EVENT OF
          ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE OR OTHER
          CAUSES BEYOND ITS DIRECT CONTROL, AND HAS NO RESPONSIBILITY FOR ANY
          ADDITIONAL EXPENSES, OMISSIONS, DELAYS, RE-ROUTING OR ACTS OF ANY
          GOVERNMENT OR AUTHORITY. <br />
          11.4 IN NO EVENT SHALL Indie 4x4 by Medraft BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
          ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS TO, DISPLAY
          OF OR USE OF THE SERVICES OR WITH THE DELAY OR INABILITY TO ACCESS,
          DISPLAY OR USE THE SERVICES (INCLUDING, BUT NOT LIMITED TO, YOUR
          RELIANCE UPON RECOMMENDATIONS APPEARING ON THE SERVICES; ANY COMPUTER
          VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS AND SERVICES
          OBTAINING THROUGH THE SERVICES; OR OTHERWISE ARISING OUT OF THE ACCESS
          TO, DISPLAY OF OR USE OF THE SERVICES) WHETHER BASED ON A THEORY OF
          NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, CONSUMER PROTECTION
          STATUTES, OR OTHERWISE, AND EVEN IF Indie 4x4 by Medraft HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            12. LIMITATION OF LIABILITY
          </span>
          <br />
          10.1. IN NO EVENT SHALL THE COMPANY, ANY OF ITS AFFILIATE, OR ANY OF
          OUR LICENSORS OR SUPPLIERS BE LIABLE UNDER, OR OTHERWISE IN CONNECTION
          WITH, THESE TERMS, FOR:
          <br />
          (A) ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE
          DAMAGES;
          <br />
          (B) ANY LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF REVENUE, OR LOSS OF
          ANTICIPATED SAVINGS;
          <br />
          (C) ANY LOSS OF, OR DAMAGE TO, DATA, REPUTATION, OR GOODWILL; AND/OR
          <br />
          (D) THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES.
          <br />
          10.2. THE COMBINED AGGREGATE LIABILITY OF THE COMPANY AND ALL THE
          COMPANY's AFFILIATES UNDER, OR OTHERWISE IN CONNECTION WITH, THESE
          TERMS SHALL NOT EXCEED THE GREATER OF: (A) TWENTY U.S. DOLLARS (US
          $20), AND (B) THE AMOUNTS ACTUALLY PAID BY YOU (IF ANY) TO THE COMPANY
          DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE
          EVENT GIVING RISE TO LIABILITY.
          <br />
          10.3. THESE LIMITATIONS WILL NOT APPLY TO THE EXTENT PROHIBITED BY
          LAW.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>13. INDEMNITY</span>
          <br />
          You agree to defend, indemnify and hold harmless Company and our
          affiliates, and our respective officers, directors, employees and
          agents, from and against any and all claims, damages, obligations,
          losses, liabilities, costs and expenses (including but not limited to
          attorney's fees) arising from: (i) your use of, or inability to use,
          the Services; (ii) interaction with any other User or Supplier; or
          (iii) your violation of these Terms.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>14. SUSPENSION</span>
          <br />
          In the event that you make use of the Services in a manner contrary to
          any applicable laws and/or these Terms, then Company may, in addition
          to any other right or remedy that it may have available to it at law
          or in equity, terminate these Terms and/or your access to the
          Services, and you will not be entitled to any claim to or restitution
          of the funds that you may have paid in regards to the use of the
          Services.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>15. TERM AND TERMINATION</span>
          <br />
          15.1 These Terms are effective until terminated by the Company or you.
          <br />
          15.2 Without derogating from the foregoing, the Company, in its sole
          discretion, has the right to terminate these Terms and/or your access
          to the Services, or any part thereof, immediately at any time and with
          or without cause (including, without any limitation, for a breach of
          these Terms).
          <br />
          15.3 The Company shall not be liable to you or any third party for
          termination of the Services, or any part thereof. If you object to any
          term or condition of these Terms, or any subsequent modifications
          thereto, or become dissatisfied with the Services in any way, your
          only recourse is to immediately discontinue use of the Services.
          <br />
          15.4 Upon termination of these Terms, you shall cease all use of the
          Services.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            16. INDEPENDENT CONTRACTORS
          </span>
          <br />
          You and Indie 4x4 by Medraft are independent contractors. Nothing in these
          Terms creates a partnership, joint venture, agency, or employment
          relationship between you and Indie 4x4 by Medraft. You must not under any
          circumstances make, or undertake, any warranties, representations,
          commitments or obligations on behalf of Indie 4x4 by Medraft.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>17. MODIFICATION</span>
          <br />
          The Company is entitled to change from time to time according to its
          absolute discretion and without needing to inform the User the
          Services and its contents, including inter alia its design and the
          services that are provided in it, and it is also entitled to edit its
          Terms.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>18. ASSIGNMENT</span>
          <br />
          These Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you but may be assigned by the Company
          without restriction or notification to you. Any prohibited assignment
          shall be null and void.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>19. SEVERABILITY</span>
          <br />
          If any part of these Terms is deemed unlawful, void or for any reason
          unenforceable, then that provision shall be deemed to be severable
          from the rest of these Terms and shall not affect the validity and
          enforceability of any of the remaining provisions of these Terms. In
          such cases, the part deemed invalid or unenforceable shall be
          construed in a manner consistent with applicable law to reflect, as
          closely as possible, the original intent of the parties.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>20. GOVERNING LAW</span>
          <br />
          The Company reserves the right to discontinue or modify any aspect of
          the Services at any time. These Terms and the relationship between you
          and the Company shall be governed by and construed in accordance with
          the laws of the State of Israel, without regard to its principles of
          conflict of laws. The exclusive jurisdiction in every matter and/or
          issue regarding the terms of use will be exclusively granted to the
          court in the district of Tel Aviv-Jaffa.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>21. MISCELLANEOUS</span>
          <br />
          These Terms shall constitute the entire agreement between you and the
          Company concerning the Services. No waiver of any term of these Terms
          shall be deemed a further or continuing waiver of such term or any
          other term, and a party's failure to assert any right or provision
          under these Terms shall not constitute a waiver of such right or
          provision. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE
          ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES MUST COMMENCE
          WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH
          CAUSE OF ACTION IS PERMANENTLY BARRED.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>22. COMMUNICATION</span>
          <br />
          You agree that Indie 4x4 by Medraft may send you notices by email, via your
          account, by regular mail, and/or via postings on or through Service.
          Except as stated otherwise in these Terms or required by Law
          applicable to you, you agree to send all notices to Indie 4x4 by Medraft to:
          support@Indie4x4.com <br />
        </p>
      </Content>

<Footer/>
    </>
  );
};

export default TermsAndConditions;
